<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <h3 class="inBlock">直播间列表</h3>
        </div>
        <div class="box">
            <div class="btnbox">
                <span class="shou addliveroom" @click="$router.push('/live/addliveroom')">
                    <i class="el-icon-video-camera"></i> 创建直播间
                </span>
            </div>
            <div class="roomlist">
                <div class="tiaojian">
                    <div class="left">
                        <div class="checkboxBox">
                            <span style="font-size: 14px; margin-right: 10px; color: #606266">是否关闭:</span>
                            <el-radio v-model="form.status" :label="null">全部</el-radio>
                            <el-radio v-model="form.status" :label="1">未关闭</el-radio>
                            <el-radio v-model="form.status" :label="0">已关闭</el-radio>
                        </div>
                        <strong>直播间名称: </strong>
                        <el-input
                            v-model="form.title"
                            placeholder="请输入直播间名称以筛选"
                        ></el-input>
                    </div>
                    <span class="shaixuan shou" @click="filterList">筛选</span>
                </div>

                <el-table
                    :data="liveRoomList"
                    border
                    style="width: 100%">
                    <el-table-column
                        prop="title"
                        label="直播名称"
                        width="180">
                        <div slot-scope="scope">
                            {{scope.row.title}}
                        </div>
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="主播姓名"
                        width="180">
                        <div slot-scope="scope">
                            {{scope.row.username}}
                        </div>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="直播分类"
                        width="180">
                        <div slot-scope="scope">
                            {{scope.row.cate_name}}
                        </div>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        width="120"
                        label="直播状态">
                        <div slot-scope="scope">
                            <span v-if="scope.row.status ==1">未直播</span>
                            <span v-if="scope.row.status ==2">直播中</span>
                            <span v-if="scope.row.status ==3">已关闭</span>
                        </div>
                    </el-table-column>
                    <el-table-column
                        prop="address"
						width="100"
                        label="直播方式">
                        <div slot-scope="scope">
                            <span v-if="scope.row.orientation ==1">横屏</span>
                            <span v-if="scope.row.orientation ==2">竖屏</span>
                        </div>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="直播开始时间">
                        <div slot-scope="scope">
                            {{scope.row.appointment_time | datefilter}}
                        </div>
                    </el-table-column>
                    <el-table-column label="操作" width="620">
                        <div class="caozuo" slot-scope="scope">
                            <el-button
                                type="primary"
                                round
                                size="mini"
                                @click="bianji(scope.row.id)"
                            >编辑</el-button>
                            <el-button
                                size="mini"
                                type="danger"
                                round
                                @click="del(scope.row.id)"
                            >删除</el-button>
                            <el-button
                                round
                                size="mini"
                                style="background-color: #1dbf9e; color:#ffffff"
                                @click="close(scope.row.id)"
                            >关闭</el-button>
							<el-button
							    type="primary"
							    round
							    size="mini"
                                style="margin-right:10px"
							    @click="getGroupMemberList_click(scope.row.id,scope.row.title)"
							>群成员</el-button>
                            <router-link :to="{path:'/live/live_goods',query: {liveid:scope.row.id}}">
                                <el-button
							    type="warning"
							    round
							    size="mini"
                                style="margin-right:10px"
							>商品管理</el-button>
                            </router-link>
							<el-button round type="success" size="mini" @click="liveNumber(scope.row.id)">直播人数设置</el-button>
                            <router-link :to="{path:'/live/inviteRanking',query: {liveid:scope.row.id}}" v-if="scope.row.status ==2">
                                <el-button
							    type="warning"
							    round
							    size="mini"
                                style="margin-left:10px"
							>门店邀约排行</el-button>
                            </router-link>
                        </div>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin-top: 20px"
                    @current-change="crtChg"
                    :current-page="form.page"
                    layout="prev, pager, next, jumper"
                    :total="total"
                    >
                </el-pagination>
            </div>
        </div>
		<el-dialog
		  :title="title"
		  :visible.sync="dialogVisible"
		  width="30%"
		  >
		    <el-table
		      :data="MemberList"
		      style="width: 100%">
		      <el-table-column
		        prop="nickname"
		        label="用户昵称"
		        width="180">
		      </el-table-column>
		      <el-table-column
		        prop="Role"
		        label="群成员身份"
		        width="180">
				  <template slot-scope="scope">
					{{scope.row.Role=='Owner'?'群主':''}}
					{{scope.row.Role=='Admin'?'管理员':''}}
					{{scope.row.Role=='Member'?'成员':''}}
				  </template>
		      </el-table-column>
		      <el-table-column
		        label="操作">
				<template slot-scope="scope">
					<!-- :disabled="scope.row.Role=='Owner'?true:false" -->
					<el-button :disabled="scope.row.Role=='Owner'?true:false"  :class="Date.parse(new Date())>scope.row.ShutUpUntil*1000?'blue':'red'" @click="modifyGroupMemberInfo_click(scope.row.Member_Account,scope.row.ShutUpUntil)" type="text">
					 {{Date.parse(new Date())>scope.row.ShutUpUntil*1000?'禁言一天':'解除禁言'}}	
					</el-button>
					<el-button :disabled="scope.row.Role=='Owner'?true:false" @click="guanliyuan(scope.row.Member_Account,scope.row.Role)" type="text">
						{{scope.row.Role=="Admin"?'解除管理员':'设为管理员'}}	
					</el-button>
				</template>
		      </el-table-column>
		    </el-table>	
			<el-pagination
			    style="margin-top: 20px"
			    @current-change="crtChgs"
			    :current-page="pages"
			    layout="prev, pager, next, jumper"
			    :total="totals"
			    >
			</el-pagination>
		  <!-- <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		  </span> -->
		</el-dialog>
        <el-dialog
		  title="直播人数设置"
		  :visible.sync="dialogVisibleNumber"
		  width="30%"
		  >
            <el-form ref="form" :model="formLiveNumber" label-width="120px">
                <el-form-item label="实时在线人数">
                        <el-input v-model="formLiveNumber.online" disabled></el-input>
                </el-form-item>
                <el-form-item label="虚拟添加人数">
                        <el-input v-model="formLiveNumber.virtual_number" disabled></el-input>
                </el-form-item>
                <el-form-item label="设置虚拟人数">
                        <el-input v-model="formLiveNumber.virtualNumbere"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button @click="dialogVisibleNumber = false">取消</el-button>
                </el-form-item>
            </el-form>
		</el-dialog>
    </el-card>

</template>
<script>
import {
  findLiveRoomList,
  deleteLiveRoom,
  closeLiveRoom,
  getGroupMemberList,
  modifyGroupMemberInfo,
  findLiveRoomDetail,
  postSaveVirtualNumber
} from "@/api/api.js";
export default {
    // props: ["id"],
    data(){
        return{
            form:{
                status: '',
                page: 1,
                limit: 10,
                title:''
            },
            formLiveNumber:{
                online: 0,
                virtual_number: 0,
                virtualNumbere: ''
            },
			pages:1,
			dialogVisible: false,
            dialogVisibleNumber: false, // 直播间人数设置弹窗
            total: 1,
			totals: 1,
            liveRoomList:[],
			title:'',
			id:'',
            liveId:'',
			MemberList:[]
        }
    },
    methods:{
        crtChg(e) {
            console.log(e);
            this.form.page = e;
            this.getlist();
        },

        filterList() {
            console.log(123);
            this.form.page = 1
            this.getlist()
        },

        // 获取直播间列表
        async getlist() {
            const { data } = await findLiveRoomList(this.form);
            this.total = data.data.total;
            this.liveRoomList = data.data.data;
        },

        bianji(id) {
			
            this.$router.push({ path: "/live/addliveroom", query: { id } });
        },
		// 禁言
		modifyGroupMemberInfo_click(Member_Account,ShutUpUntil){
			console.log(ShutUpUntil)
			let Join_Time=0
			let is_ShutUpTime=1
			// 当前时间大于JoinTime设置禁言
			console.log(Date.parse(new Date()),ShutUpUntil*1000)
			if(Date.parse(new Date())>=ShutUpUntil*1000){
				Join_Time=86400
			}else{
				Join_Time=0
			}
			// return false
			this.modifyGroupMemberInfo_set(this.id,Member_Account,Join_Time,is_ShutUpTime)
		},
		guanliyuan(Member_Account,Role){
			let role_=''
			if(Role=='Admin'){
				role_='Member'
			}else{
				role_='Admin'
			}
			this.modifyGroupMemberInfo_set(this.id,Member_Account,'',0,role_)
		},
		async modifyGroupMemberInfo_set(id,Member_Account,Join_Time,is_ShutUpTime,Role){
			let datas={
				live_id:id,
				Member_Account:Member_Account,
				ShutUpTime:Join_Time,
				is_ShutUpTime:is_ShutUpTime,
				Role:Role
			}
			const { data } = await modifyGroupMemberInfo(datas);
			if(data.code==200){
				 this.$message({
				  showClose: true,
				  message: '操作成功',
				  type: 'success'
				});
			}
			this.getGroupMemberLists(this.id)
		},
		// 群成员列表分页
		crtChgs(val){
			this.pages=val
			this.getGroupMemberLists(this.id)
		},
		getGroupMemberList_click(id,title){
			this.id=id
			this.title=title
			this.dialogVisible=true
			this.getGroupMemberLists(id)
		},
        // 设置直播间人数
        async liveNumber(ids){
            this.dialogVisibleNumber = true
            this.liveId = ids
            this.formLiveNumber.virtualNumbere = ''
            // 获取直播间详情
            const { data } = await findLiveRoomDetail({id: this.liveId});
            if (data.code != 200) return false;
            console.log(data.data)
            this.formLiveNumber.online = data.data.live_log.online
            this.formLiveNumber.virtual_number = data.data.live_log.virtual_number
        },
        // 提交设置直播间人数
        async onSubmit(){
            const { data } = await postSaveVirtualNumber({id: this.liveId, virtual_number: this.formLiveNumber.virtualNumbere});
            if (data.code != 200){
                this.$message({
                    type: "error",
                    message: data.data,
                });
            }else{
                this.$message({
                    type: "success",
                    message: "设置成功!",
                });
                this.dialogVisibleNumber = false
            }
        },
		// 群成员列表
		
		async getGroupMemberLists(id) {
			let datas={
				live_id:id,
				page:this.pages,
				limit:10,
			}
		    const { data } =await getGroupMemberList(datas);
			console.log(data)
			this.MemberList=data.data.MemberList
		    this.totals = data.data.total;
		    // this.liveRoomList = data.data.data;
		},
        // 删除直播间
        del(id){
           this.$confirm("此操作将永久删除改直播间, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
            const { data } = await deleteLiveRoom({ id: id });
            if (data.code != 200)
                return this.$message.error(data.data);
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
                this.getlist();
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            });
        },

        // 关闭直播间
        close(id){
            this.$confirm("此操作将关闭改直播间, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
            const { data } = await closeLiveRoom({ id: id });
            if (data.code != 200)
                return this.$message.error(data.data);
                this.$message({
                    type: "success",
                    message: "关闭成功!",
                });
                this.getlist();
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消关闭",
                });
            });
        }


    },

    created() {
		// alert('4')
        this.getlist();
    },
}
</script>
<style>
	.blue{color: #0275D8;}
	.red{color: #DA0618;}
</style>
<style lang="less" scope>
.box-card {
  flex: 1;
  .box {
    .btnbox {
        margin-bottom: 20px;
        .addliveroom {
            background-color: #009688;
            color: #fff;
            padding: 6px 10px;
            border-radius: 3px;
        }
    }


    .tiaojian {
        margin: 20px 0;
        padding: 10px;
        display: flex;
        align-items: center;
        background-color: #edeff0;
        position: relative;
        .left {
          strong {
            font-weight: normal;
            font-size: 14px;
            color: #606266;
            padding-top: 10px;
            margin-right: 2px;
          }
          .el-input {
            width: 300px;
            margin-right: 20px;
            /deep/.el-input__inner {
              height: 30px;
            }
          }
          .checkboxBox {
            padding-top: 10px;
            margin: 0 0 20px 0;
          }
        }

        .shaixuan {
          background-color: #18bdff;
          color: aliceblue;
          padding: 3px 10px;
          border-radius: 2px;
          position: absolute;
          bottom: 15px;
          left: 400px;
          &:hover {
            background-color: #1abe9c;
          }
        }
      }

    .roomlist{
        .title{
            font-size: 13px;
            background-color: #eee;
            padding: 10px;
            margin: 5px 0 20px 0;
            p{
                input{
                    height: 30px;
                    margin-left: 5px;
                    font-size: 12px;
                }
            }
        }
    }

  }

}
</style>